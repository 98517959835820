<template>
  <el-row class="cart">
    <el-col class="title">购物车({{carGoodsNum}})</el-col>
    <el-col :span="24">
      <CartHeader></CartHeader>
      <CartBuyInfoFreeMail  :itemDatas="goodDatas"></CartBuyInfoFreeMail>
      <CartBuyInfo :itemDatas="goodDatas"></CartBuyInfo>
      <CartBottom  @ChangeDatasFun="ChangeDatasFun"  :class="{is_fixed:isFixed}"></CartBottom>
    </el-col>
  </el-row>
</template>

<script>
  const CartBottom = () => import("components/ShopBuy/CartBottom.vue");
  const CartBuyInfo = () => import("components/ShopBuy/CartBuyInfo.vue");
  const CartBuyInfoFreeMail = () => import("components/ShopBuy/CartBuyInfoFreeMail.vue");
  const CartHeader = () => import("components/ShopBuy/CartHeader.vue");
  import {
    mapActions,
    mapState
  } from "vuex";
  export default {
    name: 'Cart',
     data() {
      return {
        goodDatas:[],
        scroll:'',
        scrollHeight:'',
        isFixed:true,
      };
    },
    components: {
      CartHeader,
      CartBuyInfo,
      CartBottom,
      CartBuyInfoFreeMail
    },
    mounted(){
      // 监听区域滚动条事件
        window.addEventListener('scroll',this.handleScroll)
    },
    methods: {
      ...mapActions({
        getGoods: "getGoods"
      }),
      ChangeDatasFun(data){
        this.goodDatas=data
      },
      handleScroll(){
          //或者使用document.querySelector('.class或者#id').scrollTop
          this.scroll = document.documentElement.scrollTop||document.body.scrollTop
          this.scrollHeight=document.documentElement.scrollHeight-document.documentElement.clientHeight- this.scroll 
          // console.log(this.scrollHeight)
          if(this.scrollHeight<291){
            this.isFixed=false
          }else{
            this.isFixed=true
          }
      }
    },
    computed: {
      ...mapState({
        carGoodsNum: state => state.carGoodsNum
      })
    },
    created() {
      this.getGoods();
      if(window.innerHeight==document.documentElement.clientHeight){
        this.isFixed=false
      }
    },
     //销毁,否则跳到别的路由还是会出现
     destroyed(){
        window.removeEventListener('scroll',this.handleScroll)
    }
  }
</script>

<style lang="less" scoped>
  @import '~style/index.less';
.headerBox{
  display: none;
}
  .cart {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
  }

  .title {
    color: @theme;
    font-size: 16px;
    line-height: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .is_fixed{
    position: fixed;
    bottom:0;
    z-index: 99;
    background: #fff;
  }
</style>